<template>
  <div>
  <el-button type="primary" @click="open()">工资条</el-button>
     <el-dialog
      ref=""
      width="70%"
      id="PaySlip"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="工资条"
      :visible.sync="dialogheaderVisible"
    >
      <PaySlipLists ></PaySlipLists>
    </el-dialog>
  </div>

</template>

<script>
  // import PaySlipLists from "@/views/demo/PaySlip/paySlipItem.vue";
  import PaySlipLists from "@/views/demo/PaySlip/PaySlip_lists.vue";
  export default {
    name: "PaySlip",
    data(){
      return{
        dialogheaderVisible:false,
      }
    },
    components: {PaySlipLists},
    methods:{
      open (){
        this.dialogheaderVisible = true
      }
    }
  };
</script>

<style scoped>

</style>
