<template>
    <div>
        <h2 :style="{fontSize:size,background:bg}">
            <span :style="{color:textColor}">{{text}}</span>
            <span class="num" v-if="num">{{num}}</span>
        </h2>
        <slot>

        </slot>
    </div>

 
</template>

<script>
  export default {
    name: "PaySlipList",
    props: {
        text: {
            type: String,
            default: () => (
            ''
            )
        },
          textColor: {
            type: String,
            default: () => (
            ''
            )
        },
          size: {
            type: String,
            default: () => (
            ''
            )
        },
         num: {
            type: Number,
            default: () => (
             0
            )
        },
          bg: {
            type: String,
            default: () => (
             '#e7e7e7'
            )
        },
    },
    data(){
      return{
        dialogItem:false,
      }
    }
  };
</script>

<style scoped>
  h2{
      height: 40px;
      line-height: 40px;
      background: #e7e7e7;
      padding-left: 24px;
  }
  .num{
      font-size: 18px;
      color: #000;
      float: right;
      margin-right: 60px;
  }
</style>