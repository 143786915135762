<template>
  <div>
      <PaySlipList text="员工信息/Employee Information" textColor="#590a0a" size="14px">
          <ul>
            <li>
              <div>
                <span>中文名/Chinese Name</span><span>李慧娟</span>
              </div>
            </li>
            <li>
              <div>
                <span>员工编码/Employee Code</span><span>020012</span>
              </div>
            </li>
            <li>
              <div>
                <span>英文名/English Name</span><span>Lillian Li</span>
              </div>
            </li>
            <li>
               <div>
                <span>法人实体/Legal Entity</span><span>美延维修服务(上海)有限公司\Repair</span>
              </div>
            </li>
            <li>
               <div>
                <span>部门/Business Department</span><span>Claim\Claim</span>
              </div>
            </li>
          </ul>
      </PaySlipList>
      <PaySlipList text="收入/Earnings" textColor="#55860e" size="14px">
          <ul>
            <li>
              <div>
                <span>其他补贴/Other Allowance</span><span>200.00</span>
              </div>
            </li>
            <li>
              <div>
                <span>实际基本工资/Actual Base Salary</span><span>7200.00</span>
              </div>
            </li>
          </ul>
      </PaySlipList>
      <PaySlipList text="社保公积金-个人/Social Benefit & Housing Fund – Employee" textColor="#0e6e92" size="14px">
          <ul>
            <li>
              <div>
                <span>个人社保合计/Total EE Social Benefit Contribution</span><span>200.00</span>
              </div>
            </li>
            <li>
              <div>
                <span>个人公积金合计/Total EE Housing</span><span>7200.00</span>
              </div>
            </li>
               <li>
              <div>
                <span>个人养老/EE Pension</span><span>200.00</span>
              </div>
            </li>
            <li>
              <div>
                <span>个人公积金/EE Housing</span><span>7200.00</span>
              </div>
            </li>
             <li class="full_half">
              <div>
                <span>个人医疗/EE Medical</span><span>7200.00</span>
              </div>
            </li>
             <li class="full_half">
              <div>
                <span>个人失业/EE Unemp.</span><span>7200.00</span>
              </div>
            </li>
          </ul>
      </PaySlipList>
          <PaySlipList text="社保公积金-公司/Social Benefit & Housing Fund – Employer" textColor="#334c0b" size="14px">
          <ul>
            <li>
              <div>
                <span>企业社保合计/Total ER Social Benefit Contribution</span><span>200.00</span>
              </div>
            </li>
            <li>
              <div>
                <span>企业公积金合计/Total ER Housing</span><span>7200.00</span>
              </div>
            </li>
               <li>
              <div>
                <span>企业养老/ER Pension</span><span>200.00</span>
              </div>
            </li>
            <li>
              <div>
                <span>企业公积金/ER Housing Fund</span><span>7200.00</span>
              </div>
            </li>
             <li class="full_half">
              <div>
                <span>企业医疗/ER Medical</span><span>7200.00</span>
              </div>
            </li>
             <li class="full_half">
              <div>
                <span>企业失业/ER Unemployment</span><span>7200.00</span>
              </div>
            </li>
               <li class="full_half">
              <div>
                <span>企业工伤/ER Injury</span><span>7200.00</span>
              </div>
            </li>
             <li class="full_half">
              <div>
                <span>企业生育/ER Maternity</span><span>7200.00</span>
              </div>
            </li>
          </ul>
      </PaySlipList>
           <PaySlipList text="个人所得税/Individual Income Tax" textColor="#681515" size="14px">
          <ul>
            <li>
              <div>
                <span>税前工资/Tax Income_Salary</span><span>200.00</span>
              </div>
            </li>
            <li>
              <div>
                <span>个人所得税合计/IIT Total</span><span>7200.00</span>
              </div>
            </li>
          
             <li class="full_half">
              <div>
                <span>应发工资/Gross Salary</span><span>7200.00</span>
              </div>
            </li>
             <li class="full_half">
              <div>
                <span>准予免除的个人社保公积金/
IIT Exemption of Social Benefits</span><span>7200.00</span>
              </div>
            </li>
             
          </ul>
      </PaySlipList>
          <PaySlipList text="税后收入/Untaxable Income" textColor="#126f5c" size="14px">
          <ul>
           
             <li class="full_half">
              <div>
                <span>税后加项/Addition After Tax</span><span>7200.00</span>
              </div>
            </li>
             
          </ul>
      </PaySlipList>
      <PaySlipList text="实发工资/Net Pay" textColor="#000" size="14px" num="5917.31" bg="#ccc">

      </PaySlipList>
       <PaySlipList text="本年度累计/Yearly Income" textColor="#000" size="14px">
    <ul>
            
          
             <li class="full">
              <div>
                <span>本年度税前收入/Yearly GrossIncome</span><span>7200.00</span>
              </div>
            </li>
             <li class="full">
              <div>
                <span>年度净收入/Yearly NetPay</span><span>7200.00</span>
              </div>
            </li>
              <li class="full">
              <div>
                <span>年度个人所得税/Yearly Total Tax</span><span>7200.00</span>
              </div>
            </li>
            <li class="full">
              <div>
                <span>累计工资薪金收入(含当月)/Cumulative Salary&Wages Income</span><span>7200.00</span>
              </div>
            </li>
             <li class="full">
              <div>
                <span>累计应纳税额/Cumulative Tax Payable Amount</span><span>7200.00</span>
              </div>
            </li>
             <li class="full">
              <div>
                <span>累计子女教育扣除额/Cumulative Children's Education Deduction</span><span>7200.00</span>
              </div>
            </li>
            <li class="full">
              <div>
                <span>累计继续教育扣除额/Cumulative Continuing Education Deduction</span><span>7200.00</span>
              </div>
            </li>
             <li class="full">
              <div>
                <span>累计住房租金扣除额/Cumulative Housing Rental Deduction</span><span>7200.00</span>
              </div>
            </li>
              <li class="full">
              <div>
                <span>累计住房贷款利息扣除额/Cumulative Housing Mortgage Interest Deduction</span><span>7200.00</span>
              </div>
            </li>
              <li class="full">
              <div>
                <span>累计赡养老人扣除额/Cumulative Care for the Elderly Deduction</span><span>7200.00</span>
              </div>
            </li>
                 <li class="full">
              <div>
                <span>累计专项附加扣除合计/Cumulative Total Additional Special Deduction</span><span>7200.00</span>
              </div>
            </li>




          </ul>
      </PaySlipList>
  </div>
 
</template>

<script>
  import PaySlipList from "@/views/demo/PaySlip/template/PaySlip_list.vue";
  export default {
    name: "PaySlipLists",
    components: {PaySlipList},
    data(){
      return{
        dialogItem:false,
      }
    }
  };
</script>

<style scoped >
  ul{
    padding: 6px 40px 10px;
    display: flex;
    flex-wrap: wrap;
  }
  ul li{
      width: 50%;
      padding: 0 20px;
      box-sizing: border-box;
    }
    ul li div{
      display: flex;
      justify-content: space-between;
    }
     ul li div span{
       height: 32px;
       line-height: 30px;
       color: #666;
     }
     ul li div span:nth-of-type(2){
       color: #000
     }
      ul li.full_half,ul li.full{
        width: 100%
      }
     ul li.full_half div{
        width: 50%;
        padding-right: 20px;
        box-sizing: border-box;
     }
       ul li.full div{
        width: 100%;
        box-sizing: border-box;
     }
</style>
